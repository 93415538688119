.table-sort-header {
  cursor: pointer;

  & > button {
    visibility: none;
    margin-right: auto;
  }

  &:hover > button {
    visibility: initial;
  }
}