.profile-cards-container {
  position: absolute;
  top: 60px;
  left: 50%;
  width: 80%;

  transform: translateX(-50%);
}

.cursor-pointer {
  cursor: pointer;
}

.hover-gray {
  &:hover {
    background-color: #eeeeeed5;
    cursor: pointer;
  }
}