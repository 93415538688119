.signup {
  background: #1A2038;
  // max-width: 1600px;
  margin: 0 auto;

  .signup-content {
    height: 100%;
    // min-width: 40vw;
    // max-width: 90%;
    // background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .signup-card {
    min-width: 30vw;
    max-width: 500px;
    img {
      height: 100px;
      object-fit: contain;
    }
  }
}

@media only screen and (max-width: 768px) {
  .signup-content {
    min-width: 100vw !important;
    background: #1A2038 !important;
  }
  .signup-card {
    min-width: 90% !important;
  }
}

@media only screen and (min-width: 992px) {
  .signup-content {
    min-width: 30vw !important;
  }
}
